import React from 'react';
import './LegalClause.css';

const LegalClause = () => (
  <div className="LegalClause">
    <h3>Cláusula para formularios web</h3>
    <b>Lab_uhardilla</b>
    , informa que los datos de carácter personal que se proporcionen cumplimentando el presente formulario serán tratados como responsable de esta web.
    <br />
    <br />
    <b>Finalidad:</b>
    {' '}
    la información recopilada se utilizará con la finalidad de responder a la consulta realizada/prestar el servicio solicitado/realizar la facturación/enviar información comercial.
    <br />
    <b>Legitimación:</b>
    {' '}
    Marcando la casilla de aceptación de la política de privacidad prestas el consentimiento para que tratemos tus datos de carácter personal.
    <br />
    <b>Plazo de conservación de los datos:</b>
    {' '}
    Los datos proporcionados se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales.
    <br />
    Como interesado te informamos que los datos facilitados quedarán depositados en los servidores de nuestro proveedor de hosting.
  </div>
);

export default LegalClause;
