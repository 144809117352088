import 'antd/dist/antd.css';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import React, { useState } from 'react';
import EMAILJS from '../../emailjs/emailjs';
import '../theme.css';
import './ContactForm.css';

const { TextArea } = Input;

const checkValues = (variables) => {
  const { message, from, reply } = variables;
  if (from === '' || from === ' ') {
    return false;
  } if (reply === '' || reply === ' ') {
    return false;
  } if (message === '' || message === ' ') {
    return false;
  }
  return true;
};

const ContactForm = () => {
  const [variables, setVariables] = useState({
    message: '',
    from: '',
    reply: '',
  });

  const handleSend = () => {
    if (typeof window !== 'undefined') {
      if (checkValues(variables)) {
        EMAILJS.initializeEmailjs();
        EMAILJS.sendMessage(variables)
          .then((res) => message.success('Se ha enviado el mensaje, en breve nos pondremos en contacto contigo!'))
          .catch((err) => message.error('Oops! No se ha podido enviar el mensaje, inténtalo de nuevo en unos minutos!'));
      } else {
        message.error('Se te ha olvidado rellenar algún campo');
      }
    }
  };

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    const newVariables = { ...variables };
    newVariables[name] = value;
    setVariables(newVariables);
  };

  return (
    <div className="ContactForm columns wrap">
      <div className="colC">
        <div>
          <h2>Contacto</h2>
        </div>
        <div>
          <div><label htmlFor="from">¿Quién eres?</label></div>
          <Input name="from" value={variables.from} onChange={handleChange} />
        </div>
        <div>
          <div><label htmlFor="reply">Tu email</label></div>
          <Input name="reply" value={variables.reply} onChange={handleChange} />
        </div>
        <div>
          <div><label htmlFor="message">Tu mensaje</label></div>
          <TextArea rows={4} value={variables.message} name="message" onChange={handleChange} />
        </div>
        <div className="columns">
          <a className="button" href="#" onClick={handleSend}>ENVIAR</a>
        </div>
      </div>
      <div className="colE" />
    </div>

  );
};

export default ContactForm;
