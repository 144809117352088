const EMAILJS = (function () {
  return { initializeEmailjs, sendMessage };

  function initializeEmailjs() {
    if (typeof window !== 'undefined') {
      window.emailjs.init('user_vPtzMfjTpCtfWZtRglwx0');
    }
  }

  function sendMessage(variables) {
    return window.emailjs.send(
      'gmail', 'info_request',
      { message_html: variables.message, from_name: variables.from, reply_to: variables.reply },
    );
  }
}());

export default EMAILJS;
