import React, { useEffect, useRef, useState } from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import '../components/ContactForm/ContactForm.css';
import Content from '../components/Content/Content';
import HQ from '../components/Landing/landing.jpeg';
import Layout from '../components/Layout/layout';
import LegalClause from '../components/LegalClause/LegalClause';
import SEO from '../components/seo';
import LAZY_LOAD from '../lazyLoad/lazyLoad';


const SecondPage = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const imageContainer = useRef(null);

  useEffect(() => {
    LAZY_LOAD.lazyLoad(imageContainer, setImgLoaded, HQ);
  }, []);
  return (
    <Layout>
      <SEO title="Contacto" />
      <div ref={imageContainer} className="ContactFormBackground" />
      <Content>
        <ContactForm />
      </Content>
      <Content>
        <LegalClause />
      </Content>
    </Layout>
  );
};

export default SecondPage;
